@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-thin::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

.scrollbar-thin::-webkit-scrollbar-track {
  margin: 0.25rem;
}
.scrollbar-thin {
  scrollbar-width: unset;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.min-w-\[4\.8125rem\] {
  min-width: 4.8125rem;
}
.min-w-\[4\.1875rem\] {
  min-width: 4.1875rem;
}
.min-w-\[13\.25rem\] {
  min-width: 13.25rem;
}
.min-w-\[8\.25rem\] {
  min-width: 8.25rem;
}
.min-w-\[5\.4375rem\] {
  min-width: 5.4375rem;
}
.min-w-\[3\.75rem\] {
  min-width: 3.75rem;
}
.min-w-\[7\.8125rem\] {
  min-width: 7.8125rem;
}
.max-w-\[9\.9375rem\] {
  max-width: 9.9375rem;
}
.min-w-\[5\.875rem\] {
  min-width: 5.875rem;
}
.max-w-\[5\.875rem\] {
  max-width: 5.875rem;
}
.min-w-\[6\.125rem\] {
  min-width: 6.125rem;
}
.max-w-\[6\.125rem\] {
  max-width: 6.125rem;
}
.max-w-\[6\.75rem\] {
  max-width: 6.75rem;
}

// .scrollbar-none::-webkit-scrollbar{
//   scrollbar-width: none !important;
// }
.scrollbar-thumb-violet-800 {
  --scrollbar-thumb: rgba(191, 191, 191, 0.5) !important;
  &:hover {
    --scrollbar-thumb: rgba(191, 191, 191, 1) !important;
  }
}
.scrollbar-thin {
  scrollbar-width: unset;
}
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="PT-Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="PT-Sans"]::before {
  font-family: "PT Sans", cursive;
  content: "PT Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
  font-family: "Sans Serif", cursive;
  content: "Sans Serif" !important;
}
/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}
.ql-font-PT-Sans {
  font-family: "PT Sans";
}
.ql-font-sans-serif {
  font-family: "Sans Serif";
}
.before\:size-2\.5:before {
  height: 0.625rem;
  width: 0.625rem;
}
.h-dvh {
  height: 100dvh;
}

.swiper-scrollbar {
  height: 4px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 4px;
}
.swiper-scrollbar-drag {
  height: 100%;
  background: rgba(191, 191, 191, 0.5);
  border-radius: 8px;
}
.swiper:hover .swiper-scrollbar-drag {
  background: rgba(191, 191, 191, 1);
}
